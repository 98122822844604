import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import styles from './DevOps.module.scss';
import {
  DEVOPS_CONSULTING_SERVICES_CARDS, DEVOPS_CONSULTING_SERVICES_HEADING, DEVOPS_CONSULTING_SERVICES_TEXT,
  DEVOPS_DEDICATED_TEAM_CARDS, DEVOPS_DEDICATED_TEAM_HEADING, DEVOPS_DEDICATED_TEAM_SUBHEADING, DEVOPS_HEADING,
  DEVOPS_SUBHEADING, DEVOPS_SUCCESS_STORIES_SUBHEADING, DEVOPS_TECHNOLOGIES_BLOCK_HEADING,
  DEVOPS_TECHNOLOGIES_BLOCK_SUBHEADING, DEVOPS_TECHNOLOGIES_CARDS, DEVOPS_WHY_CARDS, DEVOPS_WHY_HEADING,
  DEVOPS_WHY_DESCRIPTION
} from '../../constants/devops';
import Typography from '@material-ui/core/Typography';
import ExpandingCard from '../ExpandingCard';
import HorizontalCard from '../HorizontalCard';
import InnerPagesUpperBanner from '../InnerPagesUpperBanner';
import LeaveMessageBanner from '../LeaveMessageBanner';
import SuccessStoriesBanner from '../SuccessStoriesBanner';

const DevOps = () => {
  const {bg, icons, logos, minus, plus, successStoriesBackground} = useStaticQuery(graphql`
      query {
          bg: allFile(
              filter: {
                  sourceInstanceName: { eq: "devOps" }
                  name: { eq: "bg" }
              }
          ) {
              nodes {
                  name
                  publicURL
              }
          }
          icons: allFile(
              filter: {
                  sourceInstanceName: { eq: "devOps" }
                  extension: { eq: "svg" }
              }
          ) {
              nodes {
                  name
                  publicURL
              }
          }
          logos: allFile(
              filter: {
                  sourceInstanceName: { eq: "devOps" }
                  extension: { in: ["jpg", "png"] }
              }
          ) {
              nodes {
                  name
                  publicURL
              }
          }
          successStoriesBackground: allFile(
              filter: {
                  sourceInstanceName: { eq: "otherImages" }
                  name: { eq: "success-stories-bg" }
              }
          ) {
              nodes {
                  name
                  publicURL
              }
          }
          plus: allFile(
              filter: {
                  sourceInstanceName: { eq: "otherImages" }
                  name: { eq: "plus" }
              }
          ) {
              nodes {
                  name
                  publicURL
              }
          }
          minus: allFile(
              filter: {
                  sourceInstanceName: { eq: "otherImages" }
                  name: { eq: "minus" }
              }
          ) {
              nodes {
                  name
                  publicURL
              }
          }
      }
  `);

  return (
    <div className={styles['services-devops']}>
      <InnerPagesUpperBanner
        bg={bg.nodes[0].publicURL}
        className={styles['devops-banner']}
        description={DEVOPS_SUBHEADING}
        descriptionClassName={styles['devops-banner-description']}
        text={DEVOPS_HEADING}
        textClassName={styles['devops-banner-text']}
      />

      <div className={`${styles['section-container']} ${styles['consulting-services']}`}>
        <div className={styles['container']}>
          <Typography variant={'h2'} className={styles['heading']} color="secondary" align="center">
            {DEVOPS_CONSULTING_SERVICES_HEADING}
          </Typography>
          <Typography className={styles['subheading']}>{DEVOPS_CONSULTING_SERVICES_TEXT}</Typography>
          <div className={styles['card-list']}>
            {DEVOPS_CONSULTING_SERVICES_CARDS.map(({icon, text, title}) => {
              return <div className={styles['expanding-card']} key={icon}>
                <ExpandingCard
                  collapser={minus.nodes[0].publicURL}
                  expander={plus.nodes[0].publicURL}
                  img={icons.nodes.find(({name}) => name === icon).publicURL}
                  smallCard
                >
                  {{
                    collapsedView: <Typography
                      className={styles['expanding-card-heading']}
                      color="secondary"
                      variant="h4"
                    >
                      {title}
                    </Typography>,
                    expandedView: <div className={`expanding-card-container ${styles['expanding-card-container']}`}>
                      <p className={styles['expanding-card-paragraph']}>{text}</p>
                    </div>
                  }}
                </ExpandingCard>
              </div>;
            })}
          </div>
        </div>
      </div>

      <div className={`${styles['section-container']} ${styles['non-white']}`}>
        <div className={styles.container}>
          <Typography className={styles.heading} variant="h2" color="secondary" align="center">
            {DEVOPS_DEDICATED_TEAM_HEADING}
          </Typography>
          <Typography className={styles.subheading}>{DEVOPS_DEDICATED_TEAM_SUBHEADING}</Typography>
          <div className={styles['dedicated-team-cards-container']}>
            {DEVOPS_DEDICATED_TEAM_CARDS.map(({icon, title}) => {
              return <div className={styles['dedicated-team-card']} key={icon}>
                <img
                  className={styles['dedicated-team-card-icon']}
                  src={icons.nodes.find(({name}) => name === icon).publicURL}
                />
                <Typography className={styles['dedicated-team-card-title']}>{title}</Typography>
              </div>;
            })}
          </div>
        </div>
      </div>

      <SuccessStoriesBanner
        backgroundImage={successStoriesBackground.nodes[0].publicURL}
        subHeading={DEVOPS_SUCCESS_STORIES_SUBHEADING}
      />

      <div className={styles['why-devops']}>
        <Typography variant={'h2'} className={styles['why-devops__heading']} color="secondary" align="center">
          {DEVOPS_WHY_HEADING}
        </Typography>

        <div className={styles['why-devops__text']}>
          <Typography style={{fontWeight: 500}}>
            {DEVOPS_WHY_DESCRIPTION}
          </Typography>
        </div>

        <div className={styles['why-devops__cards-block']}>
          {DEVOPS_WHY_CARDS.map(({icon, title, text}) => (
            <React.Fragment key={title}>
              <div className={styles['card-container']}>
                <HorizontalCard
                  icon={icons.nodes.find(({name}) => name === icon).publicURL}
                  title={title}
                  text={text}
                />
              </div>
              <div className={styles['card-container-mobile']}>
                <ExpandingCard img={icons.nodes.find(({name}) => name === icon).publicURL}
                               smallCard>
                  {{collapsedView: (
                    <>
                      <Typography
                        variant={'h4'}
                        style={{
                          fontSize: '14px',
                          lineHeight: '24px',
                          width: '100%'
                        }}
                      >
                        {title}
                      </Typography>
                    </>
                    ),
                    expandedView: (
                    <div className={'expanding-card-container'}>
                      <hr
                        style={{
                          background: '#DFE4ED'
                        }}
                      />
                      {text}
                    </div>)}}
                </ExpandingCard>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>

      <div className={`${styles['section-container']} ${styles['technologies-block']} ${styles['non-white']}`}>
        <div className={styles.container}>
          <Typography className={styles.heading} variant={'h2'} color="secondary" align="center">
            {DEVOPS_TECHNOLOGIES_BLOCK_HEADING}
          </Typography>
          <Typography className={styles.subheading}>
            {DEVOPS_TECHNOLOGIES_BLOCK_SUBHEADING}
          </Typography>

          <div className={`${styles['technologies-grid']} ${styles['technologies-grid-desktop']}`}>
            {DEVOPS_TECHNOLOGIES_CARDS.map((column, index) => <div key={`techcologies-column-${index}`}>
              {column.map(({cardLogos, title}) => <div className={styles['technologies-card']} key={title}>
                <Typography className={styles['technologies-card-title']} variant="h3">{title}</Typography>
                <div className={styles['technologies-card-content']}>
                  {cardLogos.map(item => <img
                    alt={item}
                    className={styles['technologies-card-logo']}
                    key={item}
                    src={logos.nodes.find(({name}) => name === item).publicURL}
                  />)}
                </div>
              </div>)}
            </div>)}
          </div>
          <div className={`${styles['technologies-grid']} ${styles['technologies-grid-mobile']}`}>
            {DEVOPS_TECHNOLOGIES_CARDS.flat().sort((a, b) => a.mobileIndex - b.mobileIndex).map(({cardLogos, title}) => <div className={styles['technologies-card']} key={title}>
              <Typography className={styles['technologies-card-title']} variant="h3">{title}</Typography>
              <div className={styles['technologies-card-content']}>
                {cardLogos.map(item => <img
                  alt={item}
                  className={styles['technologies-card-logo']}
                  key={item}
                  src={logos.nodes.find(({name}) => name === item).publicURL}
                />)}
              </div>
            </div>)}
          </div>
        </div>
      </div>
      <LeaveMessageBanner />
    </div>
  );
};

export default DevOps;
