import React from 'react';
import Layout from '../components/Layout';
import { useSiteMetadata } from '../hooks';
import DevOps from '../components/DevOps';
import {graphql, useStaticQuery} from "gatsby";

const DevOpsTemplate = ({ location }) => {
  const { subtitle: siteSubtitle } = useSiteMetadata();
  const { bg } = useStaticQuery(graphql`
    query {
      bg: allFile(filter: { sourceInstanceName: { eq: "preview" } name: { eq: "devops" } }) {
        nodes {
          name
          publicURL
        }
      }
    }
  `);
  return (
    <Layout
      title="DevOps as a Service"
      description={siteSubtitle}
      location={location}
      socialImage={bg.nodes[0].publicURL}
    >
      <DevOps />
    </Layout>
  );
};

export default DevOpsTemplate;
